<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab 4: Question 3</h2>

      <p class="mb-5">
        Determine the correct step in the experimental procedure for its intended purpose.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td class="font-weight-bold">Purpose</td>
            <td class="font-weight-bold">Procedure</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Generating the oxidizing agent</td>
            <td>
              <v-select
                v-model="inputs.studentAnswer1"
                class="d-sm-inline-block ml-3 mr-3"
                style="width: 360px"
                dense
                :items="items"
                label="Select Option"
              />
            </td>
          </tr>
          <tr>
            <td>Destroying the oxidizing agent</td>
            <td>
              <v-select
                v-model="inputs.studentAnswer2"
                class="d-sm-inline-block ml-3 mr-3"
                style="width: 360px"
                dense
                :items="items"
                label="Select Option"
              />
            </td>
          </tr>
          <tr>
            <td>Testing for remaining oxidizing agent</td>
            <td>
              <v-select
                v-model="inputs.studentAnswer3"
                class="d-sm-inline-block ml-3 mr-3"
                style="width: 360px"
                dense
                :items="items"
                label="Select Option"
              />
            </td>
          </tr>
          <tr>
            <td>Drying the product</td>
            <td>
              <v-select
                v-model="inputs.studentAnswer4"
                class="d-sm-inline-block ml-3 mr-3"
                style="width: 360px"
                dense
                :items="items"
                label="Select Option"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'uci51LCPrelab4Q2',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer1: null,
        studentAnswer2: null,
        studentAnswer3: null,
        studentAnswer4: null,
      },
      items: [
        {
          text: 'Touch a drop of the reaction mixture onto a piece of KI-starch paper.',
          value: 'touchPaper',
        },
        {text: 'Add sodium sulfate to the hexane layers.', value: 'addSodium'},
        {text: 'Add bleach dropwise into a flask containing acetic acid.', value: 'bleachDropwise'},
        {text: 'Add isopropanol.', value: 'addAlcohol'},
      ],
    };
  },
};
</script>
<style scoped></style>
